@import url('https://fonts.googleapis.com/css?family=Handlee');
@import url('https://fonts.googleapis.com/css?family=Alegreya');
@import url('https://fonts.googleapis.com/css?family=Raleway');

.me {
    height: 80vmin;
    width: auto;

    max-width: 100%;
    max-height: 45vmax;
    
    border-radius: 15px;
    margin: 20px;
    box-shadow: 0px 0px #DADADA;
    transition: .3s;

    align-self: center;

}

.me:hover {
    box-shadow: 8px 8px #DADADA;
    transform: translate(-6px, -6px);
}


.bio-container {
    height: 100%;
    color: #DADADA;
    justify-content: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: calc(15px + .8vw);
    margin-left: calc(10px - 5vw);
    padding: 0px 3vw 0px 3vw;
    font-family: 'Raleway', sans-serif;
}

.title {
    font-size: calc(35px + 1.1vw);
    font-family: 'Handlee', sans-serif;
}

hr {
    width: 92%;
    border: .5px solid #D0D0D0;
    margin-top: 30px;
    margin-bottom: 30px;
}

@media only screen and (max-width: 480px) {
    .bio-container {
        margin-left: 0px;
    }
}