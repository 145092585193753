

.chatbot-form {
    font-size: calc(15px + .7vw);
    margin: auto;
}

.output-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.output {
    color: #DADADA;
    text-align: center;
    font-size: calc(15px + .7vw);
    margin: 0px 10px 20px 10px;
    max-width: 80vw;
}